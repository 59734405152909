import React from "react"
import Layout from "../shared/layout/layout"
import Seo from "../shared/seo/seo"
import CompanyImg from "../components/company/company-img"
import "../styles/company.scss"
import { graphql, useStaticQuery } from "gatsby"

const Company = () => {
  const data = useStaticQuery(graphql`
    {
      site {
        siteMetadata {
          company {
            img_description
            title
            paragraphs {
              label
            }
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <Seo title={data.site.siteMetadata.company.title} />
      <div className="row no-gutters">
        <div className="d-flex flex-column justify-content-end col-xl-6 col-lg-6 col-md-12 h-100">
          <div className="row no-gutters">
            <div className="col-xl-11 col-lg-11 col-md-11 col-sm-11 col-xs-12  h-100">
              <div className="main-img">
                <CompanyImg />
              </div>
            </div>
            <div className="col-xl-1 col-lg-1 col-md-1 col-sm-1 col-xs-12  h-100">
              <div className="title-img">
                {data.site.siteMetadata.company.img_description}
              </div>
            </div>
          </div>
        </div>
        <div className="col-xl-6 col-lg-6 col-md-12">
          <div className="paragraphs">
            {data.site.siteMetadata.company.paragraphs.map((item, index) => {
              return (
                <p className="paragraph-item" key={index}>
                  {item.label}
                </p>
              )
            })}
          </div>
        </div>
      </div>
    </Layout>
  )
}
export default Company
